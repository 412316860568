import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";

import stateLogo from "../../assets/images/app/plate-talk/state-logo.svg";
import cityLogo from "../../assets/images/app/plate-talk/city-logo.svg";
import viewIcon from "../../assets/images/app/common/listing-view-image.svg";
import addressLogo from "../../assets/images/app/plate-talk/address-logo.svg";
import phoneLogo from "../../assets/images/app/plate-talk/phone.svg";

import { DataTable } from "../../components/DataTable";
import { SubscriptionType } from "../../constants";
import { GetUserById, GetVehicles, UpdateUserStatusById } from "../../api/api.service";
import { MODULE_KEYS, getKey } from "../../api/api.keys";
import { useRequestProcessor } from "../../hooks/useRequestProcessors";
import { useLocalPaginatedRequest } from "../../hooks/useLocalPaginatedRequest";
import { CustomSwitchInput } from "../../components/Input";
import { SUCCESS, showToast } from "../../utils/toastUtils";
import { ConfirmationModal } from "../../components/CustomModal";
import { getStatus, findStatusChecked } from "./utils";
import { hasData } from "../../utils/commonUtils";
import { SummaryCard } from "../../components/SummaryCard";
import LabeledDataItem from "../../components/LabeledDataItem/LabeledDataItem";
import { MODAL_CONTENT_CONFIG } from "./ModalContentConfig";
import { monthYear } from "../../utils/dateUtils";
import { dateTimeFormat } from "../../utils/dateFormatUtils";
import { SUBSCRIPTION_TYPE } from "../../constants/SubscriptionType";
import { getMediaPath } from "../../utils/mediaUtils";
import LoaderFallbackUI from "../../components/LoaderFallbackUI/LoaderFallbackUI";
import { APP_ROUTES, getParamsAttachedRoute } from "../../helpers/routeHelpers";
import { DATA_LABELS } from "./Config";
import { VEHICLES_COLUMNS } from "../VehicleManagement/Config";

const recordUpdateSuccessMessage = "Status has been updated successfully";
const format = dateTimeFormat.appDateFormat;

const Detail = () => {
    const queryClient = useQueryClient();
    const history = useHistory();
    const { userID } = useParams();
    let { useMutate, useFetch } = useRequestProcessor();

    const [modalConfig, setModalConfig] = useState(null);
    const [isUserStatusUpdating, setIsUserStatusUpdating] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({ status: "", id: "" });

    const { isFetching, data } = useLocalPaginatedRequest({
        key: getKey([MODULE_KEYS.VEHICLE_MANAGEMENT]),
        requestFn: GetVehicles,
        dataKey: "Data",
        totalKey: "Count",
        params: {
            UserId: userID,
            Page: 1,
            Limit: 6
        }
    });

    const {
        data: userData,
        isLoading,
        refetch: refetchUserDetail,
        isFetching: isUserDataFetch
    } = useFetch(
        getKey([MODULE_KEYS.USER_MANAGEMENT_DETAILS], { userID }),
        async () => await GetUserById(userID)
    );

    let { mutate } = useMutate("", getKey(), (data) =>
        UpdateUserStatusById({ Id: selectedRecord?.id, bodyData: data })
    );

    const handleActionInit = (id, status) => {
        setSelectedRecord({ id, status });
        let statusToSet = getStatus(status);
        setModalConfig(MODAL_CONTENT_CONFIG[statusToSet]);
    };

    const handleActionCancellation = () => {
        setModalConfig(null);
    };

    // handling USER status update
    const handleUserStatusUpdate = () => {
        setIsUserStatusUpdating(true);
        let statusToSet = getStatus(selectedRecord.status);
        const bodyData = { Status: statusToSet };
        // Api call here
        mutate(bodyData, {
            onSuccess: async (res) => {
                setIsUserStatusUpdating(false);
                showToast(SUCCESS, recordUpdateSuccessMessage);

                setModalConfig(null);
                refetchUserDetail();
            },
            onError: (err) => {
                setIsUserStatusUpdating(false);
            }
        });
    };

    if (isUserDataFetch || isLoading || !hasData(userData)) {
        return (
            <div style={{ height: "70vh" }}>
                <LoaderFallbackUI isLoader={isUserDataFetch} className={"h-100"} />
            </div>
        );
    }

    return (
        <div className="my-1 px-4">
            <div className="d-flex align-items-center justify-content-between "></div>
            <SummaryCard colClassName="mb-4">
                <Row className="user-detail-container">
                    <div className="d-flex justify-content-end px-4">
                        <div>
                            <CustomSwitchInput
                                label="Block"
                                isChecked={findStatusChecked(userData?.Status)}
                                size="small"
                                onClick={() => handleActionInit(userData?.Id, userData?.Status)}
                            />
                        </div>
                    </div>
                    <Col md={12} lg={12} xl={5}>
                        {" "}
                        <div className="user-detail">
                            <div className="user-detail-image">
                                <img src={getMediaPath(userData?.Media)} alt="eye-icon" />
                            </div>
                            <Col md={12}>
                                <div className="user-detail-name">{userData?.FullName}</div>
                                <div className="user-detail-subconatiner">
                                    <div
                                        className={`job-status-common ${
                                            userData?.Subscription?.Product?.Name ==
                                            SUBSCRIPTION_TYPE.FREE
                                                ? SubscriptionType.getLabelClass(
                                                      userData?.Subscription?.Product?.Name
                                                  )
                                                : "cs-badge-vibrant-orange"
                                        }`}
                                    >
                                        {userData?.Subscription?.Product?.Name || "-"}
                                    </div>
                                    {userData?.Subscription?.Product?.Name !==
                                        SUBSCRIPTION_TYPE.FREE &&
                                        userData?.Subscription?.Product?.Name !==
                                            SUBSCRIPTION_TYPE.ALWAYS && (
                                            <div className={"cs-badge-subscription-date"}>
                                                {"Subscribed "}

                                                {monthYear(
                                                    "MMM-DD",
                                                    userData?.Subscription?.CreatedAt
                                                )}
                                            </div>
                                        )}
                                </div>
                            </Col>
                        </div>
                    </Col>

                    <Col md={12} lg={12} xl={7} className="data-labels-container px-4">
                        <div style={{ color: "black" }} className="">
                            <Row>
                                <Col md={6} className="px-2">
                                    <LabeledDataItem
                                        title={DATA_LABELS.DATE_JOINED}
                                        desc={
                                            userData?.CreatedAt
                                                ? monthYear(format, userData?.CreatedAt)
                                                : "N/A"
                                        }
                                        titleColProps={{ xs: 5, md: 6, lg: 6 }}
                                        descColProps={{ xs: 7, md: 6, lg: 6 }}
                                        icon="bx bx-calendar"
                                        iconClass="icon-class"
                                        titleClass="titleClass"
                                        descClass="desc-class"
                                        rowClass="py-2"
                                    />

                                    <LabeledDataItem
                                        title={DATA_LABELS.MOBILE}
                                        desc={
                                            userData?.CountryCode && userData?.PhoneNumber
                                                ? `${userData?.CountryCode}${userData?.PhoneNumber}`
                                                : "N/A"
                                        }
                                        titleColProps={{ xs: 5, md: 6, lg: 6 }}
                                        descColProps={{ xs: 7, md: 6, lg: 6 }}
                                        menuImg={phoneLogo}
                                        imgClass="img-class"
                                        titleClass="titleClass"
                                        descClass="desc-class"
                                        rowClass="py-2"
                                    />
                                    <LabeledDataItem
                                        title={DATA_LABELS.CITY}
                                        desc={userData?.AddressCity || "N/A"}
                                        titleColProps={{ xs: 5, md: 6, lg: 6 }}
                                        descColProps={{ xs: 7, md: 6, lg: 6 }}
                                        menuImg={cityLogo}
                                        imgClass="img-class"
                                        titleClass="titleClass"
                                        descClass="desc-class"
                                        rowClass="py-2"
                                    />
                                </Col>
                                <Col md={6} className="px-2">
                                    <LabeledDataItem
                                        title={DATA_LABELS.EMAIL}
                                        desc={userData?.Email || "N/A"}
                                        titleColProps={{ xs: 5, md: 5, lg: 4 }}
                                        descColProps={{ xs: 7, md: 7, lg: 8 }}
                                        icon="bx bx-envelope"
                                        iconClass="icon-class"
                                        titleClass="titleClass"
                                        descClass="desc-class"
                                        rowClass="py-2"
                                    />
                                    <LabeledDataItem
                                        title={DATA_LABELS.VEHICLES}
                                        desc={userData?.VehicleCount || "N/A"}
                                        titleColProps={{ xs: 5, md: 5, lg: 4 }}
                                        descColProps={{ xs: 7, md: 7, lg: 8 }}
                                        icon="bx bx-car"
                                        iconClass="icon-class"
                                        titleClass="titleClass"
                                        descClass="desc-class"
                                        rowClass="py-2"
                                    />
                                    <LabeledDataItem
                                        title={DATA_LABELS.STATE}
                                        desc={userData?.AddressState || "N/A"}
                                        titleColProps={{ xs: 5, md: 5, lg: 4 }}
                                        descColProps={{ xs: 7, md: 7, lg: 8 }}
                                        menuImg={stateLogo}
                                        imgClass="img-class"
                                        iconClass="icon-class"
                                        titleClass="titleClass"
                                        descClass="desc-class"
                                        rowClass="py-2"
                                    />

                                    <LabeledDataItem
                                        title={DATA_LABELS.ADDRESS}
                                        desc={userData?.Address || "N/A"}
                                        titleColProps={{ xs: 5, md: 5, lg: 4 }}
                                        descColProps={{ xs: 7, md: 7, lg: 8 }}
                                        menuImg={addressLogo}
                                        imgClass="img-class"
                                        titleClass="titleClass"
                                        descClass="desc-class"
                                        rowClass="py-2"
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </SummaryCard>
            <div className="vehicle-detail-class mt-4">{"Vehicles Details"}</div>
            <SummaryCard colClassName={"mt-2"}>
                <DataTable
                    tableContainerClass="mt-4"
                    loading={isFetching}
                    data={data}
                    config={[
                        {
                            title: VEHICLES_COLUMNS.S_NO,
                            className: "px-4",
                            headerClass: "column-header",
                            render: (data) => <div>{data.Id || "-"}</div>
                        },
                        {
                            title: VEHICLES_COLUMNS.PLATE_NO,
                            headerClass: "column-header",
                            render: (data) => (
                                <div className="user">
                                    <div className="image">
                                        <img src={getMediaPath(data?.Medias[0])} alt="eye-icon" />
                                    </div>
                                    <div>{data?.PlateNumber}</div>
                                </div>
                            )
                        },
                        {
                            title: VEHICLES_COLUMNS.LINKED,
                            headerClass: "column-header",
                            render: (data) => (
                                <div>{data?.LinkedUsers?.length > 0 ? "Yes" : "No"}</div>
                            )
                        },
                        {
                            title: VEHICLES_COLUMNS.VEHICLE_TYPE,
                            headerClass: "column-header",
                            render: (data) => <div>{data?.VehicleType || "-"}</div>
                        },
                        {
                            title: VEHICLES_COLUMNS.MAKE,
                            headerClass: "column-header",
                            render: (data) => <div>{data?.Manufacturer || "-"}</div>
                        },
                        {
                            title: VEHICLES_COLUMNS.MODEL,
                            headerClass: "column-header",
                            render: (data) => <div>{data?.Model || "-"}</div>
                        },
                        {
                            title: VEHICLES_COLUMNS.YEAR,
                            headerClass: "column-header",
                            render: (data) => <div>{data?.Year || "-"}</div>
                        },

                        {
                            title: VEHICLES_COLUMNS.ACTION,
                            headerClass: "column-header",
                            render: (data) => {
                                return (
                                    <div className="d-flex">
                                        <img
                                            style={{
                                                backgroundColor: "#D7D7D",
                                                filter: " grayscale(100%)"
                                            }}
                                            src={viewIcon}
                                            role="button"
                                            alt={"details icon"}
                                            onClick={() => {
                                                queryClient.invalidateQueries({
                                                    queryKey: [MODULE_KEYS.USER_MANAGEMENT_DETAILS]
                                                });
                                                history.push(
                                                    getParamsAttachedRoute(
                                                        APP_ROUTES.VEHICLE_MANAGEMENT_DETAILS,
                                                        {
                                                            vehicleID: data?.Id
                                                        }
                                                    )
                                                );
                                            }}
                                        />
                                    </div>
                                );
                            }
                        }
                    ]}
                />
            </SummaryCard>
            <ConfirmationModal
                size="md"
                hasCustomConfig
                isOpen={modalConfig}
                customImageSrc={modalConfig?.imageSrc}
                customTitle={modalConfig?.modalTitle}
                customSubText={modalConfig?.modalSubText}
                customYesBtnText={modalConfig?.yesButtonText}
                customNoBtnText={modalConfig?.noButtonText}
                onYes={handleUserStatusUpdate}
                onNo={handleActionCancellation}
                yesLoading={isUserStatusUpdating}
            />
        </div>
    );
};

export default Detail;
