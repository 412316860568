var Config = {
    LIMIT: 10,
    PERSIST_SECRET_KEY: "APP_REDUX_PERSIST_STORE_KEY",
    ENVIRONMENT: "STAGING",
    ENVIRONMENTS: {
        LOCAL: {
            API_URL: "APP_API_URL",
            GOOGLE_API_KEY: "APP_GOOGLE_API_KEY"
        },
        DEVELOPMENT: {
            API_URL: "https://dev-api-platetalk.kodefuse.com",
            GOOGLE_API_KEY: "AIzaSyA1QhGFu0xIKAO0n146vl7PTf2dCk_hPNQ"
        },
        STAGING: {
            API_URL: "https://stg-api-platetalk.kodefuse.com",
            GOOGLE_API_KEY: "AIzaSyA1QhGFu0xIKAO0n146vl7PTf2dCk_hPNQ"
        },
        DEMO: {
            API_URL: "https://demo-api-pured.kodefuse.com",
            GOOGLE_API_KEY: "AIzaSyA1QhGFu0xIKAO0n146vl7PTf2dCk_hPNQ"
        },
        PRODUCTION: {
            API_URL: "https://api.platetalkapp.com",
            GOOGLE_API_KEY: "APP_GOOGLE_API_KEY"
        }
    }
};

Config.env = () => {
    return Config.ENVIRONMENTS[Config.ENVIRONMENT];
};

export default Config;
